import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';

import { CountdownModule } from 'ngx-countdown';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './navbar/navbar.component';
import { HomeComponent } from './home/home.component';
import { InfosComponent } from './infos/infos.component';
import { DayComponent } from './day/day.component';
import { PiggyComponent } from './piggy/piggy.component';
import { HistoryComponent } from './history/history.component';
import { TimelineElementComponent } from './timeline-element/timeline-element.component';
import { HistoryNewComponent } from './history-new/history-new.component';
import { ChasseComponent } from './chasse/chasse.component';
import { RSVPComponent } from './rsvp/rsvp.component';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    HomeComponent,
    InfosComponent,
    DayComponent,
    PiggyComponent,
    HistoryComponent,
    TimelineElementComponent,
    HistoryNewComponent,
    ChasseComponent,
    RSVPComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    NgbModule,
    CountdownModule,
    ReactiveFormsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
