import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-chasse',
  templateUrl: './chasse.component.html',
  styleUrls: ['./chasse.component.sass']
})
export class ChasseComponent implements OnInit {

  connecting = false;
  error = false;
  showStep = 1;

  id: string = null;
  firstName = '';
  firstName2 = null;
  maxProgress = 1;

  constructor(private http: HttpClient) {}

  ngOnInit() {
    console.log('Will ping');
    this.http.get('https://wedding-backend.azurewebsites.net/api/ping').subscribe((data) => {
      console.log(data);
    });
  }

  completedStep(step: number) {
    if (step === 1) {
      this.showStep = Math.max(2, this.maxProgress + 1);
    } else {
      this.showStep = step + 1;
    }
    this.maxProgress = Math.max(this.maxProgress, this.showStep);
    this.connecting = false;
    this.error = false;
  }

  completedWithError() {
    this.connecting = false;
    this.error = true;
  }

  // tslint:disable-next-line:member-ordering
  step1 = new FormGroup({
    password: new FormControl('')
  });

  onSubmitStep1() {
    // Check infos

    const id = this.step1.get('password').value;
    this.connecting = true;

    this.http.post<{firstName: string, firstName2: string, progress: number, code: string, url: string}>(this.stepUrl(id, 1), {}).subscribe((data) => {
      this.firstName = data.firstName;
      this.firstName2 = data.firstName2;
      this.maxProgress = data.progress;
      this.code = data.code;
      this.url = data.url;

      this.id = id;
      this.completedStep(1);
    }, error => {
      this.completedWithError();
    });
  }

  // tslint:disable-next-line:member-ordering
  step2 = new FormGroup({
    coordinates1: new FormControl(''),
    coordinates2: new FormControl(''),
    coordinates3: new FormControl('')
  });

  onSubmitStep2() {
    this.connecting = true;

    const payload = this.step2.value;

    this.http.post(this.stepUrl(this.id, 2), payload).subscribe((data) => {
      this.completedStep(2);
    }, error => {
      this.completedWithError();
    });
  }

  onSubmitStep3() {
    this.connecting = true;
    this.http.post(this.stepUrl(this.id, 3), {}).subscribe((data) => {
      this.completedStep(3);
    }, error => {
      this.completedWithError();
    });
  }

  // tslint:disable-next-line:member-ordering
  step4 = new FormGroup({
    password: new FormControl('')
  });
  // tslint:disable-next-line:member-ordering
  open = false;
  selected = false;

  onSubmitStep4() {
    this.connecting = true;

    const payload = {
      password: this.step4.get('password').value
    };

    this.http.post(this.stepUrl(this.id, 4), payload).subscribe((data) => {
      this.connecting = false;
      this.error = false;
      this.selected = false;
      this.open = true;
    }, error => {
      this.completedWithError();
    });
  }

  onClickStep4() {
    if (!this.open) {
      this.selected = true;
    }
    if (this.open) {
      this.completedStep(4);
    }
  }

  onClickStep5() {
    this.connecting = true;
    this.http.post(this.stepUrl(this.id, 5), {}).subscribe((data) => {
      this.completedStep(5);
    }, error => {
      this.completedWithError();
    });
  }

  // tslint:disable-next-line:member-ordering
  step6 = new FormGroup({
    password: new FormControl('')
  });

  onSubmitStep6() {
    this.connecting = true;

    const payload = {
      password: this.step6.get('password').value
    };

    this.http.post(this.stepUrl(this.id, 6), payload).subscribe((data) => {
      this.completedStep(6);
    }, error => {
      this.completedWithError();
    });
  }

  // tslint:disable-next-line:member-ordering
  step7 = new FormGroup({
    stop: new FormControl('')
  });

  code = '';

  onSubmitStep7() {
    this.connecting = true;

    const payload = {
      stop: this.step7.get('stop').value
    };

    this.http.post(this.stepUrl(this.id, 7), payload).subscribe((data: {code}) => {
      this.code = data.code;
      this.completedStep(7);
    }, error => {
      this.completedWithError();
    });
  }

    // tslint:disable-next-line:member-ordering
    step8 = new FormGroup({
      location: new FormControl('')
    });

    url = '';

    onSubmitStep8() {
      this.connecting = true;

      const payload = {
        location: this.step8.get('location').value
      };

      this.http.post<{url: string}>(this.stepUrl(this.id, 8), payload).subscribe((data) => {
        this.completedStep(8);
        this.url = data.url;
        // Redirect
      }, error => {
        this.completedWithError();
      });
    }

  stepUrl = (id: string, step: number) => {
    return 'https://wedding-backend.azurewebsites.net/api/id/' + id + '/step/' + step;
  }
}
