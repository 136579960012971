import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-rsvp',
  templateUrl: './rsvp.component.html',
  styleUrls: ['./rsvp.component.sass']
})
export class RSVPComponent implements OnInit {

  showForm = false;
  connecting = false;
  error = false;

  constructor(private http: HttpClient) { }


  lookupForm = new FormGroup({
    loginLastName: new FormControl(''),
    loginFirstName: new FormControl('')
  });

  rsvpForm = new FormGroup({
    firstName: new FormControl(''),
    lastName: new FormControl(''),
    firstName2: new FormControl(''),
    lastName2: new FormControl(''),
    event1: new FormControl(''),
    event2: new FormControl(''),
    event3: new FormControl(''),
    event4: new FormControl(''),
    message: new FormControl('')
  });

  hasSetPlusOne = false;
  showPlusOne = false;
  showSuccess = false;

  hasAlreadyRSVP = false;

  id = null;

  lookup() {
    const loginLastName: string = this.lookupForm.get('loginLastName').value;
    const loginFirstName: string = this.lookupForm.get('loginFirstName').value;

    this.id = (loginLastName.toLocaleLowerCase() + loginFirstName.toLocaleLowerCase().substr(0, 2)).replace(/[éö-]/gi, '');
    console.log(this.id);

    this.connecting = true;
    this.error = false;

    this.http.get<{firstName2: string, hasAlreadyRSVP: boolean}>('https://wedding-backend.azurewebsites.net/api/rsvp/' + this.id).subscribe((data) => {
      this.connecting = false;
      this.showForm = true;

      if (data.firstName2) {
        this.hasSetPlusOne = true;
      }

      console.log(data);

      this.hasAlreadyRSVP = data.hasAlreadyRSVP;

      this.rsvpForm.patchValue(data);
    }, (error) => {
      this.connecting = false;
      this.error = true;
    });
  }

  onSubmit() {
    this.connecting = true;
    this.error = false;

    const payload = this.rsvpForm.value;

    this.http.post('https://wedding-backend.azurewebsites.net/api/rsvp/' + this.id, payload).subscribe((data) => {
      this.connecting = false;
      this.showForm = false;
      this.showSuccess = true;
    }, (error) => {
      this.connecting = false;
      this.error = true;
    });
  }

  ngOnInit() {
  }

}
