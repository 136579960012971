import { Component, OnInit, ViewChild, Renderer2, OnDestroy } from '@angular/core';
import * as moment from 'moment-timezone';
import { Router, NavigationStart } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.sass']
})
export class HomeComponent implements OnInit, OnDestroy {

  public config;

  public jours;

  constructor(private renderer: Renderer2) {
    // const eventDate = moment.tz('2020-05-30 15:30:00', 'Europe/Zurich');
    const eventDate = moment.tz('2021-10-16 15:30:00', 'Europe/Zurich');

    const now = moment();

    this.jours = eventDate.diff(now, 'days') + 1;

  }

  ngOnInit() {
    this.renderer.setAttribute(document.body, 'id', 'home');
  }

  ngOnDestroy() {
    this.renderer.setAttribute(document.body, 'id', 'other');
  }

}
