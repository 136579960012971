import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { element } from 'protractor';

@Component({
  selector: 'app-piggy',
  templateUrl: './piggy.component.html',
  styleUrls: ['./piggy.component.sass']
})

export class PiggyComponent implements OnInit {

  constructor(private http: HttpClient) { }

  loading = true;
  categories: Category[] = [];

  items: ListeElement[] = [];
  /*
  selected() {
    const a = this.categories.map(c => {
      return c.elements.filter(x => x.selected);
    });
    const empty: ListeElement[] = [];
    return empty.concat(...a);

  }*/

  selected() {
    return this.items.filter(x => x.selected);
  }

  hasSelected() {
    return this.selected().length > 0;
  }

  message() {
    return this.selected().map(x => x.shortTitle).join(', ');
  }

  ngOnInit() {
    this.loading = true;
    this.http.get<ListeElement[]>('https://wedding-backend.azurewebsites.net/api/liste').subscribe((list) => {
      this.loading = false;

    this.items = list;
    list.forEach(element => {
      element.selected = false;
      element.selectable = () => {
        return !(element.quantityLeft === 0 && element.quantity);
      };
      element.clicked = () => {
        if(element.selectable()) element.selected = !element.selected;
        else element.selected = false; // can't select
      };
    });


    this.categories.push({
        title: 'Voyage de noces',
        elements: list.filter(x => x.PartitionKey === 'noce')
      }, {
        title: 'Petits plaisirs',
        elements: list.filter(x => x.PartitionKey === 'Activités')
      }, {
        title: 'Maison',
        elements: list.filter(x => x.PartitionKey === 'maison')
      });
      console.log(list);
    });
  }

}

interface Category {
  title: string;
  elements: ListeElement[];
}

interface ListeElement {
  PartitionKey: string;
  RowKey: string;
  shortTitle: string;
  title: string;
  description: string;
  quantity: number;
  quantityLeft: number;
  price: number;
  selected: boolean;
  clicked: () => void;
  selectable: () => boolean;
}
