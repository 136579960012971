import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-timeline-element',
  templateUrl: './timeline-element.component.html',
  styleUrls: ['./timeline-element.component.sass']
})
export class TimelineElementComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
